import mainjson from '../config/main';
export default function GetData() {
        return {
        "apitype":"charities",
        "id":"charity_id",
        "subidname":"charity_event_id",
        "options":["edit","insert","delete"],
        "nameSingle":"charity",
        "nameMultiple":"charities",
        "filters":
            [
               
            ]
        ,
        "fields":{
            "field2":{
                "name":"Name",
                "field":"charity_name",
                "type":"TextInputMultiLanguage",
                "required":true,
                "list":true
            },
            "field3":{
                "name":"Description",
                "field":"charity_description",
                "type":"TextAreaMultiLanguage",
                "required":true,
                "list":false
            },     
            "field4":{
                "name":"Image",
                "field":"charity_image",
                "type":"ImageUpload",
                "required":false,
                "list":true,
                "resize":{
                    width:512,
                    height:512,
                    type:"fill",
                    quality:0.75,
                    bgcolor:"#ffffff"
                },
            },
            "field5":{
                "name":"Charity donate link",
                "field":"charity_link",
                "type":"TextInput",
                "required":true,
                "list":false
            },
        }
    }
}